.sidebar {
  background-color: #000;
  margin: 0;
  border-radius: 0;
  z-index: 9;
}

.simplebar-content {
  background: #000;
}

.sidebarList a {
  color: #fff;
}

header {
  background: #0cc730 !important;
  height: 80px;
}

.MuiButton {
  background: #0cc730 !important;
}

.MuiButton-contained {
  background-color: #0cc730 !important;
  margin-top: 80px;
}

table thead th {
  background-color: #0cc730 !important;
  color: #fff !important;
}



.css-1byr0tz {
  background: #f4f5f8;
  position: relative;
  padding: 0;
  padding-top: 20px;
}

/* .MuiCardHeader
{
  background: #61e47a !important;
} */
label[data-shrink=false]+.MuiInputBase-formControl .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
  margin-left: 20%;
}

select#inputGroupSelect01 {
  width: 22% !important;
}

.form-control {

  border: 1px solid #dce0e4 !important;
  line-height: 1.78 !important;


}

.navv {
  background-color: #0cc730 !important;
  margin-top: 80px;
  padding: 8px 14px;
  text-decoration: none;
  border-radius: 9px;
}


/* ===================== FILE INPUT ===================== */
.file-area {
  width: 100%;
  position: relative;
}

.file-area input[type=file] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.file-area .file-dummy {
  width: 100%;
  padding: 30px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px dashed rgb(0 0 0 / 20%);
  text-align: center;
}

.file-area .file-dummy .success {
  display: none;
}

.file-area:hover .file-dummy {
  background: rgba(255, 255, 255, 0.1);
}

.file-area input[type=file]:focus+.file-dummy {
  outline: 2px solid rgba(255, 255, 255, 0.5);
  outline: -webkit-focus-ring-color auto 5px;
}

/* .file-area input[type=file]:valid + .file-dummy {
border-color: rgba(0, 255, 0, 0.4);
background-color: rgba(0, 255, 0, 0.3);
} */
.success img {
  width: 100px;
}

.file-area input[type=file]:valid+.file-dummy .success {
  display: inline-block;
}

.file-area input[type=file]:valid+.file-dummy .default {
  display: none;
}

/* ===================== BASIC STYLING ===================== */

h1 {
  text-align: center;
  margin: 50px auto;
  font-weight: 100;
}

label {
  font-weight: 500;
  display: block;
  margin: 4px 0;
  text-transform: uppercase;
  font-size: 13px;
  overflow: hidden;
}

label span {
  float: right;
  text-transform: none;
  font-weight: 200;
  line-height: 1em;
  font-style: italic;
  opacity: 0.8;
}

.form-controll {
  display: block;
  padding: 8px 16px;
  width: 100%;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  font-weight: 200;
}

.form-controll:focus {
  outline: 2px solid rgba(255, 255, 255, 0.5);
  outline: -webkit-focus-ring-color auto 5px;
}

.form-group {
  max-width: 500px;
  margin: auto;
  margin-bottom: 30px;
}

.back-to-article {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  position: absolute;
  right: 20px;
  top: 20px;
  text-decoration: none;
  display: inline-block;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px 18px;
  transition: all 0.3s ease-in-out;
  opacity: 0.6;
}

.back-to-article:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.8);
}


input.filter-text {
  display: block;
  width: 30%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.5;
  /* margin: 0px 0px 20px 0px; */
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  float: right;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}



.sidebar .logo-img {
  position: relative;
  top: -50px;
}

button#mui-3:hover {
  background: #0cc730;
}



/* dialog box-  */


.addfund .MuiDialog-paper {
  overflow-x: hidden;
}

.user-section {
  background-color: #000000;
  height: 100vh;
  display: flex;
  align-items: center;
}

.img-row {
  height: 100vh;
  background-color: #000;
  display: flex;
  align-items: center;
}

.multistep-form .form-container {
  border-radius: 0px;
}

.form-heading {
  background: #0cc730;
  color: #fff;
  padding: 10px;
  font-size: 25px;
  font-weight: 500;
}

button.link-btn {
  border: #fefefe;
  background: #fff;
}

button.link-btn:hover {
  text-decoration: underline;
}





.overlay {
  /* height: 80vh;
  width: 250px; */
  height: 100vh;
  width: 100%;
  /* z-index: 11111111; */
  /* overflow: scroll; */
  /* .MyLoader_overlay { */
  /* background: rgba(255, 0, 0, 0.5); */
  /* } */
}

.spinner svg {
  /* top: 63px; */
  top: 30px;
  /* left: 72px; */

}



.loder-background {
  position: absolute;
  width: 100%;
  overflow: hidden;
}

.MuiTypography-h4 {
  margin-top: 90px;
}

.css-8atqhb {
  position: relative;
  height: 100vh;
}


/* -------------------- Loder  */

.loading-overlay {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
}

.loading-overlay.is-active {
  display: flex;
}

.code {
  font-family: monospace;
  /*   font-size: .9em; */
  color: #dd4a68;
  background-color: rgb(238, 238, 238);
  padding: 0 3px;
}

.background-overlay {
  background: #000000a8;
  position: absolute;
  width: 100%;
  display: flex;
  z-index: 99;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.background-overlay img {
  width: 100px;
}

.funds {
  background: #fff;
  color: #000;
  padding: 7px;
  border-radius: 10px;
  margin-right: 14px;
}

.funds b {
  font-weight: bolder;
  color: #000;
}

.css-19kzrtu {
  padding: 0px 24px;
}

.mt-center {
  margin-top: 25%;
  display: flex;
  justify-content: center;
}

/* .marg{
  margin-top: -100px;
}
.part{
  padding-top: 80px;
}
.martt{
  padding-top: 80px;
} */
.css-wdsdfe-MuiButtonBase-root-MuiTab-root {
  min-height: auto;
}

/* form.d-block.part {
  position: relative;
  top: 75px;
}
.mb-3.marg {
  margin-top: -70px;
}
.mb-3.marg {
  margin-top: 0px;
}
@media screen and (max-width: 768px) {
  .mb-3.marg {
    margin-top: 8px;
  }
} */

div#simple-tabpanel-0,
div#simple-tabpanel-1 {
  margin-top: 20px;
}

.tab-0 {
  display: none;
}

.modal {

  z-index: 9999;
}

td.MuiTableCell-root {
  padding: 10px 7px;
  /* text-align: center; */
}

/* .tss-1bk4gez-MUIDataTable-root {
  margin-top: -80px;
} */

.filter-dropdown {
  padding: 8px !important;
  background: none !important;
  color: gray !important;
  border: 1px solid #dce0e4 !important;
  z-index: 999 ;
  position: relative;
  width: 18% !important;
}

/* 
position: relative;
width: 192px; */
.export-exce, .exportl {
  margin-top: -47px !important;
  float: right;
}
button.btn.MuiButton-contained.mt-0.export-excel {
  /* float: right !important; */
  position: absolute;
}